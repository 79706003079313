import { ref, toRef, computed, watch, getCurrentInstance } from '@vue/composition-api'
import { useValidation } from 'vue-composable'
import { debounce } from 'lodash-es'
import { parse } from 'date-fns'

const getFieldModelById = (fields, id) => {
  return fields.value.find((field) => field.id === id)?.model
}

export const useAppInputDateFields = (props, emit) => {
  const createInputField = (options) => {
    return {
      model: ref(options.value),
      ref: `${options.id}Ref`,
      analyticsName: computed(() => {
        return props.analyticsName !== '' ? props.analyticsName + '-' + options.id : ''
      }),
      label: options.label,
      id: options.id,
      maxlength: options.maxlength,
      placeholder: options.placeholder,
    }
  }

  const getValueFromProps = (idx) => {
    if (props.value) {
      return props.value.split('-')[idx]
    }
    return ''
  }

  const dayInputField = createInputField({
    id: 'day',
    label: toRef(props, 'dayLabel'), // Convert toRef to keep reactivity from prop to component
    maxlength: 2,
    placeholder: 'DD',
    value: getValueFromProps(2),
  })

  const monthInputField = createInputField({
    id: 'month',
    label: toRef(props, 'monthLabel'),
    maxlength: 2,
    placeholder: 'MM',
    value: getValueFromProps(1),
  })

  const yearInputField = createInputField({
    id: 'year',
    label: toRef(props, 'yearLabel'),
    maxlength: 4,
    placeholder: 'YYYY',
    value: getValueFromProps(0),
  })

  const fields = computed(() => {
    let inputFields = [dayInputField, monthInputField, yearInputField]
    if (props.inputFormat === 'MM/DD/YYYY') {
      inputFields = [monthInputField, dayInputField, yearInputField]
    }

    if (props.inputFormat === 'YYYY/MM/DD') {
      inputFields = [yearInputField, monthInputField, dayInputField]
    }

    /*
    Loop through the fields
     - create a watcher on the model,
     - when the model length is equal to max length focus next input
    */
    inputFields.forEach((field, fieldIdx) => {
      field.idx = fieldIdx

      watch(field.model, (value) => {
        // Used to remove any non numeric characters from model
        field.model.value = value.replace(/[^\d]/g, '')

        if (value.length === field.maxlength) {
          const nextField = fields.value[fieldIdx + 1]
          focusFieldInputByRef(nextField?.ref)
        }
      })
    })

    return inputFields
  })

  const outputValue = computed(() => {
    const day = getFieldModelById(fields, 'day')?.value
    const month = getFieldModelById(fields, 'month')?.value
    const year = getFieldModelById(fields, 'year')?.value
    const output = `${year}-${month}-${day}`
    return output === '--' ? '' : output
  })

  watch(outputValue, (value) => {
    emit('input', value)
  })

  const instance = getCurrentInstance()

  const focusFieldInputByRef = (ref) => {
    if (ref) {
      instance.refs[ref][0]?.$el.querySelector('input')?.focus()
    }
  }

  // Used to create emit object
  // Only used when focus and blur are emitted
  const emittedValue = (field, type) => {
    const value = {
      values: {
        day: getFieldModelById(fields, 'day')?.value,
        month: getFieldModelById(fields, 'month')?.value,
        year: getFieldModelById(fields, 'year')?.value,
        model: outputValue.value,
      },
    }

    value[`${type}Field`] = field

    return value
  }

  const focusCount = ref(0)
  const onFieldFocus = (field) => {
    // If component has not been focused before
    // Emit focus event
    if (focusCount.value === 0) {
      emit('focus', emittedValue(field, 'focus'))
    }

    // Emit field that triggered focus event
    emit('inputFocus', {
      field,
    })

    // Increase focus count
    if (focusCount.value < 2) {
      focusCount.value++
    }
  }

  // Replace with Vue.nextTick

  const checkFocusState = debounce((field) => {
    // Check focus count to see if during the debounce period another input has not been focus
    // If another input has been focused decrease count
    // Otherwise we can safely assume no input fields are focused and we can emit blur
    if (focusCount.value > 1) {
      focusCount.value--
    } else {
      focusCount.value = 0
      emit('blur', emittedValue(field, 'blur'))
    }
  })

  const onFieldBlur = (field) => {
    // Update field model to 0X if value enter is between 0-9
    if (field.id !== 'year') {
      const { value } = field.model
      if (value.length > 0 && !/^[0-9]{2}$/.test(value)) {
        field.model.value = `0${value}`
      }
    }

    // Emit field which triggered blur
    emit('inputBlur', {
      field,
    })

    // Check component focus state
    checkFocusState(field)
  }

  const onFieldDelete = (event, field) => {
    if (field.model.value.length === 0) {
      event.preventDefault()
      const previousField = fields.value[field.idx - 1]
      focusFieldInputByRef(previousField?.ref)
    }
  }

  return {
    fields,
    outputValue,
    isFocused: computed(() => focusCount.value > 0),
    focusFieldInputByRef,
    onFieldFocus,
    onFieldBlur,
    onFieldDelete,
  }
}

export const useIsValidDate = () => {
  const isValidDay = (day) => {
    if (day.length === 0) {
      return true
    }
    return /\b(0[1-9]|[12][0-9]|3[01])\b/.test(day)
  }

  const isValidMonth = (month) => {
    if (month.length === 0) {
      return true
    }
    return /\b(0[1-9]|1[0-2])\b/.test(month)
  }

  const isValidYear = (year) => {
    if (year.length === 0) {
      return true
    }
    return year.length <= 4
  }

  // NOTE: dateInput value supplied should always be in format yyyy-mm-dd
  const isValidDate = (dateInput) => {
    if (dateInput.length === 0) {
      return true
    }

    // Convert dateInput split values to int
    const dateSplit = dateInput.split('-')
    const year = parseInt(dateSplit[0])
    const month = parseInt(dateSplit[1])
    const day = parseInt(dateSplit[2])

    // Parse inputDate to date, inputDate is the value to be output for v-model
    // Output date format is yyyy-mm-dd string
    const date = parse(dateInput, 'yyyy-MM-dd', new Date())

    // Verify date
    // Check date year equals input year
    // Check date month equals input month, subtract 1 to handle new Date
    // Check date date equals input day
    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day
  }

  return {
    isValidDay,
    isValidMonth,
    isValidYear,
    isValidDate,
  }
}

export const useAppInputDateValidation = (options) => {
  const { isValidDay, isValidMonth, isValidYear, isValidDate } = useIsValidDate()

  // Key value pairing of error codes and their validation function
  const dateValidators = {
    day: () => isValidDay(getFieldModelById(options.fields, 'day')?.value),
    month: () => isValidMonth(getFieldModelById(options.fields, 'month')?.value),
    year: () => isValidYear(getFieldModelById(options.fields, 'year')?.value),
    zeroYear: () => getFieldModelById(options.fields, 'year')?.value !== '0000',
    default: () => isValidDate(options.outputValue.value),
  }

  // Compute the order to validate inputs based of input format
  const validationOrder = computed(() => {
    const { inputFormat } = options.props
    if (inputFormat === 'MM/DD/YYYY') {
      return ['month', 'day', 'year', 'zeroYear', 'default']
    }
    return ['day', 'month', 'year', 'zeroYear', 'default']
  })

  const errorCode = ref('')

  // Computed to return props.inputErrorMessages
  // A computed is used here to auto update errorMsg based off prop
  const errorMsg = computed(() => {
    return options.props.inputErrorMessages
  })
  // Key value lookup to get error message from error code
  // If key value is not defined return default
  const invalidErrorMessage = computed(() => {
    return errorMsg.value[errorCode.value] || errorMsg.value.default
  })

  // Create validation object for dob input fields
  const fieldValidation = useValidation({
    date: {
      $value: options.outputValue,
      isValidDob: {
        $validator(v) {
          // When input is not focused
          // Loop through validation order and execute each validator
          // If any validators are in valid set error code to key
          // and return false to display validation error
          if (v.length > 0 && !options.isFocused.value) {
            const { value: order } = validationOrder
            for (let key of order) {
              if (!dateValidators[key]()) {
                errorCode.value = key
                return false
              }
            }
          }
          return true
        },
        $message: invalidErrorMessage,
      },
      isValidDateHidden: {
        $validator(v) {
          // When input is focused
          // Loop through validation order and execute each validator
          // If any validators are in valid set error code to key
          // and return false to display validation error
          if (v.length > 0 && (options.isFocused.value || options.isFocused.value)) {
            const { value: order } = validationOrder
            for (let key of order) {
              if (!dateValidators[key]()) {
                errorCode.value = key
                return false
              }
            }
          }
          return true
        },
        $message: ' ',
      },
    },
  })

  return {
    fieldValidation,
  }
}

export const useAppInputDateRefs = () => {
  return {
    dayRef: ref(),
    monthRef: ref(),
    yearRef: ref(),
  }
}
