<template>
  <div :class="root.classes">
    <!--eslint-disable-next-line vuejs-accessibility/label-has-for-->
    <label
      v-if="label.text && !hideLabel"
      :id="label.id"
      :class="label.classes"
      :for="input.id"
      v-text="label.text"
    />
    <div :class="wrapper.classes">
      <!-- @slot Place an input element inside. provides slot props for bindings
            @binding: {object} root the root element bindings
            @binding: {object} wrapper the wrapper element bindings
            @binding: {object} label the label element bindings
            @binding: {object} input the input element bindings
            @binding: {object} validation the input element bindings
            @binding: {object} validation-helpers the input element bindings
            -->
      <slot
        :root="root"
        :wrapper="wrapper"
        :label="label"
        :input="input"
        :validation="wrapper.validation"
        :validation-helpers="validationHelpers"
      />
    </div>
    <div class="messages-container">
      <AppInputValidation
        v-if="wrapper.validation || props.helperText"
        :validation="wrapper.validation"
        :validation-helpers="validationHelpers"
        :helper-text="props.helperText"
      />
      <AppInputHelpers
        v-if="props.showCounter"
        class="input-counter"
        :helper-text="`${props.value.length}/300`"
      />
    </div>
  </div>
</template>
<!--Debug-->
<!--<div>-->
<!--  <pre>{{ root }}</pre>-->
<!--  <pre>{{ wrapper }}</pre>-->
<!--  <pre>{{ label }}</pre>-->
<!--  <pre>{{ input }}</pre>-->
<!--</div>-->

<script>
import AppInputValidation from '@/components/AppInputValidation/AppInputValidation'
import AppInputHelpers from '@/components/AppInputHelpers/AppInputHelpers'
import { useInputHelpers } from '@/composables/useInputHelpers'
import { getCurrentInstance } from '@vue/composition-api'
export default {
  name: 'AppInputWrapper',
  components: { AppInputValidation, AppInputHelpers },
  props: {
    componentValidation: {
      type: Object,
      default: () => ({}),
    },
    helperText: {
      type: String,
      required: false,
      default: '',
    },
    componentLabel: {
      type: String,
      default: null,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup($props) {
    const { parent } = getCurrentInstance()
    const { root, wrapper, label, input, validationHelpers, props } = useInputHelpers(
      parent,
      $props
    )

    return {
      root,
      wrapper,
      label,
      input,
      validationHelpers,
      props,
    }
  },
}
</script>

<style scoped lang="postcss">
.messages-container {
  @apply flex flex-row justify-between;
}
.input-counter {
  @apply right-2;
}
</style>
