<template>
  <AppInputWrapper
    v-slot="{ input }"
    :class="{
      'input--with-left-icon': $slots.leftIcon,
      'input--with-right-icon': $slots.rightIcon,
    }"
  >
    <input
      v-model="input.model"
      :type="type"
      :class="input.classes"
      v-bind="input.attrs"
      v-on="input.listeners"
    />
    <div v-if="$slots.leftIcon" class="input-left-icon">
      <!-- @slot leftIcon: For the left Icon -->
      <slot name="leftIcon"></slot>
    </div>
    <div v-if="$slots.rightIcon" class="input-right-icon">
      <!-- @slot rightIcon: For the right Icon -->
      <slot name="rightIcon"></slot>
    </div>
  </AppInputWrapper>
</template>

<script>
import AppInputWrapper from '@/components/AppInputWrapper/AppInputWrapper'
export const types = ['text', 'email', 'password', 'number']
export default {
  name: 'AppInputText',
  components: { AppInputWrapper },
  inheritAttrs: false,
  emits: ['input', 'focus', 'blur'],
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    helperText: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => {
        return types.indexOf(value) !== -1
      },
    },
    analyticsName: {
      type: String,
      default: '',
      required: !!process.env.VUE_APP_ANALYTICS_ENABLED,
    },
  },
}
</script>
