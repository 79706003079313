<template>
  <div>
    <p v-if="helperText && !validationHelpers.hasError" class="input-helper">
      {{ helperText }}
    </p>
    <template v-if="validationHelpers.hasError">
      <div :id="validationHelpers.validationId">
        <p
          v-for="(error, index) in validation.$errors"
          style="white-space: pre"
          :key="index"
          class="input-validation"
        >
          {{ error }}
        </p>
      </div>
    </template>
    <template v-if="serverError && serverError.length > 0">
      <div :id="validationHelpers.validationId + 'server'">
        <p
          v-for="(error, index) in serverError"
          :key="index"
          style="white-space: pre"
          class="input-validation"
        >
          {{ error }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AppInputHelpers',
  props: {
    validation: {
      type: Object,
      default: () => ({}),
    },
    validationHelpers: {
      type: Object,
      default: () => ({}),
    },
    helperText: {
      type: String,
      default: '',
    },
    serverError: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
