<template>
  <AppInputWrapper v-slot="{ input }">
    <div class="row">
      <div v-for="field in fields" :key="field.id" class="column">
        <AppInputText
          :ref="field.ref"
          v-model="field.model.value"
          :analytics-name="field.analyticsName.value"
          :label="field.label.value"
          class="input-date"
          type="text"
          :placeholder="field.placeholder"
          :maxlength="field.maxlength"
          v-bind="input.attrs"
          @focus="onFieldFocus(field)"
          @blur="onFieldBlur(field)"
          @keydown.delete="onFieldDelete($event, field)"
        />
      </div>
    </div>
  </AppInputWrapper>
</template>

<script>
import AppInputWrapper from '@/components/AppInputWrapper/AppInputWrapper'
import AppInputText from '@/components/AppInputText/AppInputText'
import { useAppInputDateFields, useAppInputDateRefs } from './-composable.js'

export const inputFormats = ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD']
export { useIsValidDate } from './-composable.js'

export default {
  name: 'AppInputDateOfBirth',
  components: { AppInputText, AppInputWrapper },
  emits: ['input', 'blur', 'inputBlur', 'focus', 'inputFocus'],
  props: {
    value: {
      type: String,
      default: '',
      required: true,
    },
    label: {
      type: String,
      default: 'Date',
      required: true,
    },
    monthLabel: {
      type: String,
      default: 'Month',
    },
    dayLabel: {
      type: String,
      default: 'Day',
    },
    yearLabel: {
      type: String,
      default: 'Year',
    },
    inputFormat: {
      type: String,
      default: 'DD/MM/YYYY',
      validator: (prop) => {
        return inputFormats.indexOf(prop) !== -1
      },
    },
    inputErrorMessages: {
      type: Object,
      default: () => ({
        day: 'Day must be between 1 and 31',
        month: 'Month must be between 1 and 12',
        year: 'Year must be a max of 4 characters',
        default: 'Invalid date entered',
      }),
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    helperText: {
      type: String,
      default: '',
    },
    analyticsName: {
      type: String,
      default: '',
      required: !!process.env.VUE_APP_ANALYTICS_ENABLED,
    },
  },
  setup(props, { emit }) {
    const { fields, isFocused, onFieldFocus, onFieldBlur, onFieldDelete } = useAppInputDateFields(props, emit)

    // TODO Vue2 doesn't support function refs, so we must manually make the refs, in vue3 we can improve on this
    // https://v3.vuejs.org/guide/composition-api-template-refs.html#usage-inside-v-for
    const { dayRef, monthRef, yearRef } = useAppInputDateRefs()

    return {
      dayRef,
      monthRef,
      yearRef,
      fields,
      isFocused,
      onFieldFocus,
      onFieldBlur,
      onFieldDelete,
    }
  },
}
</script>

<style lang="postcss" scoped>
.row {
  @apply flex mt-1;
}
.column {
  @apply mr-4 w-1/3;

  &:last-child {
    @apply mr-0;
  }
}
.input-date {
  @apply mb-0;
}
::v-deep .input-date .input-label {
  @apply font-normal;
}
</style>
